import * as React from 'react';

import { Builder, BuilderComponent } from '@builder.io/react';
import Link from '../components/Link/Link';

function NotFoundPage() {
  if (Builder.isPreviewing || Builder.isEditing) {
    return <BuilderComponent renderLink={Link} name={Builder.previewingModel || 'page'} />
  }

  return (
    <>
        <h2>Not found and stuff.</h2>
    </>
  )
}

export default NotFoundPage;